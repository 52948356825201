import React, { useState } from 'react';

import './inputForm.css';

const InputForm = ({
  accept,
  containerClass = '',
  disabled = false,
  error,
  id,
  inputClass = '',
  isHook,
  label,
  name,
  onChange,
  onFocus,
  placeholder,
  requeried = false,
  type = "text",
  value,
}) => {
  function handleOnChange(event) {
    if (onChange) {
      onChange(event);
    }
  }
  const fileClass = type === 'file' ? 'file-input' : '';

  return (
    <div className={`custom-input col col-sm-1 p-h-8 ${containerClass} ${fileClass}`}>
      <label htmlFor={id} className="input-from">
        <span>{label}</span>
        <input
          accept={accept}
          className={inputClass}
          disabled={disabled}
          id={id}
          name={name || id}
          onChange={handleOnChange}
          onFocus={onFocus}
          placeholder={placeholder || ''}
          required={requeried}
          type={type}
          value={value}
        />
      </label>

      <span>{error}</span>
    </div>
  );
};

export default InputForm;
