import React from 'react';
import './modalContainer.css';

const ModalContainer = ({
  children,
  contentStyle,
  handleOnKeyPress,
  handleOutClick,
  show,
  title,
  subTitle,
}) => (
  <>
    {show
      && (
        <div className="modal-container" id="modalOuter">
          <div className={'modal-container-card-content'
            + `${contentStyle || ''}`}
          >
            <div>
              <h3 className="text-primary m-8 text-center">{title}</h3>

              <div className="line m-v-16" />

              <p className="text-secondary text-center">{subTitle}</p>

              <div className="flex center">
                <button className="btn btn-black align-self-center" type="button" onClick={handleOutClick}>
                  Aceptar
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
  </>
);

export default ModalContainer;
