// const SecureToken = '9d1ea1ca-8f90-4939-816d-4815ccfcdbde';
const URL = 'https://api.eboxcode.com:3030';

export const sendEmail = async ({
  attachments = [],
  body,
  from = 'harredondo@eboxcode.com',
  subjec,
  to = 'hola@eboxcode.com',
}) => window.Email.send({
  Host: 'smtp.elasticemail.com',
  Username: 'harredondo@eboxcode.com',
  Password: '50EB96D58EE85ACE920B790D3A1FCC04A238',
  Attachments: attachments,
  Body: body,
  From: from,
  Subject: subjec,
  To: to,
});

export const sendContactEmail = async ({
  city = '',
  company = '',
  description = '',
  email = '',
  name = '',
  phone = '',
  service = '',
  state = '',
}) => {
  const body = `
    <h3>Hola, estos son mis datos: </h3>
    <br>
    <p>
      Nombre: ${name}<br>
      Corre: ${email}<br>
      Tel: ${phone}<br> 
      Compañia: ${company}<br> 
      Estado: ${state}<br>
      Ciudad: ${city}<br>
      Servicio: ${service}<br><br>
      Mensaje/Detalle: ${description}
    </p>
  `;
  return sendEmail({ body, subjec: 'Contacto' });
};

async function makeXhrRequest(formData) {
  return new Promise((resolve) => {
    const xhr = new XMLHttpRequest();
    xhr.open('POST', `${URL}/sendcv `);
    xhr.onload = () => {
      resolve({ ok: xhr.status, respose: xhr.response });
    };
    xhr.onerror = () => {
      resolve({ ok: false, response: null });
    };
    xhr.send(formData);
  });
}

export const sendEmailCv = async ({ comments = '', file, name = '', position = '', birthday = '' }) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('name', name);
  formData.append('position', position);
  formData.append('comments', comments);
  formData.append('birthday', birthday);

  const request = await makeXhrRequest(formData);
  return request;
};

export const sendQuotingEmail = async ({
  city = '',
  company = '',
  description = '',
  email = '',
  name = '',
  phone = '',
  service = '',
  state = '',
}) => {
  const body = {
    city,
    company,
    description,
    email,
    name,
    phone,
    service,
    state,
  };

  try {
    const request = await fetch(`${URL}/sendquotation`, {
      body,
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      mode: 'cors',
    });
    const response = await request.json();

    return { ok: request.ok, response };
  } catch (error) {
    return { ok: false, error: error.message };
  }
};

export function fileIoBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
      if ((encoded.length % 4) > 0) {
        encoded += '='.repeat(4 - (encoded.length % 4));
      }
      resolve(encoded);
    };
    reader.onerror = (error) => reject(error);
  });
}
