import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { sendEmailCv, fileIoBase64 } from '../../utils';

import useInputValue from '../../hooks/useInputValue';

import ModalContainer from '../../components/common/modalContainer/modalContainer';
import Layuot from '../../components/layout';
import InputForm from '../../components/common/input/inputForm';

import './cv.css';

const CVPage = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalError, setShowModalError] = useState(false);
  const [name, onChangeName] = useInputValue();
  const [job, onChangeJob] = useInputValue();
  const [date, onChangeDate] = useInputValue();
  const [message, onChangeMessage] = useInputValue();
  const [cv, setCv] = useState();
  const [cvName, setCvName] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  async function onSubmit(event) {
    event.preventDefault();

    if (loading) {
      return;
    }

    setLoading(true);

    const formatedDate = new Date(date).toLocaleDateString();
    const request = await sendEmailCv({
      file: cv,
      birthday: formatedDate,
      comments: message,
      name,
      position: job,
    });

    if (request.ok) {
      setShowModal(true);
    } else {
      setShowModalError(true);
    }

    setLoading(false);
  }

  async function onFileChange(event) {
    const { files } = event.target;

    if (files && files[0]) {
      // const img64 = await fileIoBase64(files[0]);
      setCv(files[0]);
      setCvName(files[0].name);
    }
  }

  function closeModal() {
    setShowModal(false);
  }

  function closeModalError() {
    setShowModalError(false);
  }

  return (
    <>
      <ModalContainer
        handleOutClick={closeModal}
        show={showModal}
        subTitle={t('cv.modal.subTitle')}
        title={t('cv.modal.title')}
      />

      <ModalContainer
        handleOutClick={closeModalError}
        show={showModalError}
        subTitle={t('contact.modalError.subTitle')}
        title={t('contact.modalError.title')}
      />

      <Layuot>
        <div className="container flex center">
          <div className="row width-80p mobile-flex-reverse">
            <div className="col col-sm-1">
              <div className="card">
                <span className="block text-secondary text-center m-0 m-t-8 font-18">{t('cv.formTitle')}</span>

                <form encType="multipart/form-data" onSubmit={onSubmit}>
                  <InputForm id="name" disabled={loading} placeholder={`${t('cv.name')}*`} requeried value={name} onChange={onChangeName} />

                  <InputForm id="job" disabled={loading} placeholder={`${t('cv.job')}*`} requeried value={job} onChange={onChangeJob} />

                  <InputForm id="date" disabled={loading} placeholder={`${t('cv.birthDate')}*`} type="date" requeried value={date} onChange={onChangeDate} />

                  <InputForm
                    accept=".txt, .pdf, .doc, .docx, .opt"
                    disabled={loading}
                    id="attachments"
                    label={`${t('cv.attachments')}*`}
                    onChange={onFileChange}
                    placeholder="Cv"
                    requeried
                    type="file"
                  />

                  <div className="col col-sm-1 p-h-8">
                    <label htmlFor="terms" className="input-from terms-check">
                      <input
                        disabled={loading}
                        id="terms"
                        required
                        type="checkbox"
                      />
                      <span className="text-secondary inp-terms">
                        {t('cv.termsButton')}
                        <a href="/avisodeprivacidad">{` ${t('cv.termsAndConditions')}`}</a>
                      </span>
                    </label>
                  </div>

                  <label className="input-from" htmlFor="message">
                    <textarea id="message" disabled={loading} name="message" type="text" placeholder={t('cv.message')} value={message} onChange={onChangeMessage} />
                  </label>

                  <div className="flex center p-v-8">
                    <button className="btn btn-black" disabled={loading} type="submit">{loading ? t('cv.buttonSending') : t('cv.button')}</button>
                  </div>
                </form>
              </div>
            </div>

            <div className="col col-sm-1 m-16">
              <h2 className="text-primary m-0 m-t-16 cv-center">{t('cv.title')}</h2>

              <p className="text-secondary cv-center">
                {t('cv.subTitle')}
              </p>
            </div>
          </div>
        </div>
      </Layuot>
    </>
  );
};

export default CVPage;
