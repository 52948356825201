import { useState } from 'react';

const useInputValue = () => {
  const [value, setVale] = useState('');

  function onChange(event) {
    const { target } = event;

    setVale(target.value);
  }

  return [value, onChange];
};

export default useInputValue;
